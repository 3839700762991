import Vue from 'vue'

Vue.directive('drag', {
  bind: function(el) {
    el.onmousedown = e => {
      //算出鼠标相对元素的位置
      let disX = e.clientX - el.offsetLeft
      let disY = e.clientY - el.offsetTop

      document.onmousemove = e => {
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX
        let top = e.clientY - disY

        //移动当前元素
        el.style.left = left + 'px'
        el.style.top = top + 'px'
      }
      document.onmouseup = () => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
    el.ontouchstart = e => {
      let eTouch = e.targetTouches[0]
      //算出鼠标相对元素的位置
      let disX = eTouch.clientX - el.offsetLeft
      let disY = eTouch.clientY - el.offsetTop

      document.ontouchmove = e => {
        let dTouch = e.targetTouches[0]
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = dTouch.clientX - disX
        let top = dTouch.clientY - disY

        //移动当前元素
        el.style.left = left + 'px'
        el.style.top = top + 'px'
      }
      document.ontouchend = () => {
        document.ontouchmove = null
        document.ontouchend = null
      }
    }
  }
})
