// 手机号码正则验证
export const validationMobilePhone = (number) => {
  return /(^1[1|2|3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/.test(number)
}
// 邮箱正则验证
export const validationEmail = (email) => {
  return /(^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$)/.test(
    email
  )
}
// 身份证正则验证
export const validationCardNo = (number) => {
  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(number)
}
// 获取sessionStorage
export const getSessionStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key))
}
// 设置sessionStorage
export const setSessionStorage = (key, val) => {
  sessionStorage.setItem(key, JSON.stringify(val))
}
// 清除sessionStorage
export const clearSessionStorage = (key) => {
  sessionStorage.removeItem(key)
}
// 获取图片的宽高
export const imgWidthHeightGet = (url) => {
  return new Promise((resolve) => {
    // 创建对象
    let img = new Image()
    // 改变图片的src
    img.src = url
    // 判断是否有缓存
    if (img.complete) {
      resolve({
        width: img.width,
        height: img.height,
        wHAspect: img.width / img.height,
      })
    } else {
      // 加载完成执行
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
          wHAspect: img.width / img.height,
        })
      }
    }
  })
}
// json深拷贝
export const jsonDeepClone = (json) => {
  return JSON.parse(JSON.stringify(json))
}
// 下拉数组转Map
export const selectListToMap = (list) => {
  let jsonMap = {}
  list.forEach((item) => {
    jsonMap[item.value] = item.label
  })
  return jsonMap
}
