import * as types from '@/store/mutation-types'
import { SESSION_KEY_TOKEN } from '@/config'
import { setSessionStorage, clearSessionStorage } from '@/utils'

const mutations = {
  [types.SET_TOKEN](state, token) {
    setSessionStorage(SESSION_KEY_TOKEN, token)
    state.token = token
  },
  [types.CLEAR_TOKEN](state) {
    clearSessionStorage(SESSION_KEY_TOKEN)
    state.token = ''
  },
}
export default mutations
