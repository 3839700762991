export const TIME_OUT_TIME = 30 * 1000 // 请求超时时间30秒
export const STATUS_CODE_OK = '1' // 后台返回正确信息的code标识
export const CODE_UNAUTHORIZED = 401 // 未授权CODE
export const NOTIFY_STAY_TIME = 3 * 1000 // 右上角提示notyfy停留时间3秒
export const HEADER_TOKEN_NAME = 'Authorization' // token传给后台的header名字
export const SESSION_KEY_TOKEN = 'KEY_TOKEN' // sessionStorage中的token的key值
export const YPQ_CENTER_POSITION = [121.530019, 31.294245] // 杨浦区高德地图中心点
export const YPQ_QZH_POSITION = [121.524945, 31.259229] // 杨浦区人民政府高德地图经纬度
export const BAIDU_AK = 'mryv0A7rM2FjxnwfW6IFRfAjj2mKl7nY' // 百度天气请求url
export const WEATHER_BAIDU_LOCATION = '上海' // 百度天气请求location
export const WEATHER_BAIDU_URL = `https://api.map.baidu.com/telematics/v3/weather?output=json&ak=${BAIDU_AK}&location=${WEATHER_BAIDU_LOCATION}` // 百度天气请求url
export const DICT_T_PARK_USE_TYPE = 'DICT_T_PARK_USE_TYPE' // 房间使用方式
export const DICT_T_PARK_RADAR_CHART_PRO = 'DICT_T_PARK_RADAR_CHART_PRO' // 五力图
