import moment from 'moment'
import echarts from 'echarts'
import "@/plugins/clipboard.js";
import '@/plugins/element.js'
import '@/directive/index.js'
import '@/assets/styles/normalize.scss'
import '@/assets/styles/font-awesome.min.css'
import '@/assets/styles/transition.scss'
import 'swiper/dist/css/swiper.css'
import Vue from 'vue'
import App from '@/views/App'
import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false

Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
