import Vue from "vue";
import {
  Button,
  Notification,
  Loading,
  MessageBox,
  Message,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Table,
  TableColumn,
  Row,
  Col,
  Popover,
  Input,
} from "element-ui";
Vue.prototype.$ELEMENT = {
  size: "small",
};
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dialog);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Row);
Vue.use(Col);
Vue.use(Popover);
Vue.use(Input);
Vue.prototype.$notify = Notification;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;
