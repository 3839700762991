import {
  getSessionStorage
} from '@/utils'
import {
  SESSION_KEY_TOKEN
} from '@/config'
const state = {
  token: getSessionStorage(SESSION_KEY_TOKEN) ? getSessionStorage(SESSION_KEY_TOKEN) : ''
}
export default state
